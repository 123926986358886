import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  style: {"z-index":"1"},
  class: "table-content"
}
const _hoisted_2 = { class: "p-input-icon-left" }
const _hoisted_3 = { class: "custom-header" }
const _hoisted_4 = { class: "custom-header" }
const _hoisted_5 = { class: "custom-header" }
const _hoisted_6 = { class: "custom-header" }
const _hoisted_7 = { class: "custom-header" }
const _hoisted_8 = { class: "custom-header" }
const _hoisted_9 = { class: "custom-header" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "dropdown-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("i", { class: "pi pi-search" }, null, -1)),
        _createVNode(_component_InputText, {
          modelValue: _ctx.globalFilter,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.globalFilter) = $event)),
          placeholder: "Buscar en la tabla",
          class: "p-mr-2"
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_DataTable, {
        value: _ctx.filteredCustomers,
        paginator: "",
        rows: 10,
        rowsPerPageOptions: [5, 10, 20, 50],
        tableStyle: "min-width: 80rem",
        globalFilter: _ctx.globalFilter
      }, {
        empty: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("message.solicitud.s08")), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "id",
            style: {"width":"10%"}
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("message.solicitud.s01")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "created_at",
            style: {"width":"15%"}
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("message.solicitud.s02")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "transaccion",
            style: {"width":"15%"}
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("message.solicitud.s03")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "siteId",
            style: {"width":"10%"}
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("message.solicitud.s04")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "user_email",
            style: {"width":"20%"}
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("message.solicitud.s05")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, { style: {"width":"10%"} }, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("message.solicitud.s06")), 1)
            ]),
            body: _withCtx((slotProps) => [
              _createVNode(_component_Tag, {
                value: slotProps.data.master_status_name,
                severity: _ctx.getSeverity(slotProps.data)
              }, null, 8, ["value", "severity"])
            ]),
            _: 1
          }),
          (_ctx.permissions.some((evt) => evt.id === 118))
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 0,
                style: {"width":"20%"}
              }, {
                header: _withCtx(() => [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("message.solicitud.s07")), 1)
                ]),
                body: _withCtx((slotProps) => [
                  (!['Procesado', 'Cancelado'].includes(slotProps.data.master_status_name))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_Dropdown, {
                            options: _ctx.getOptions(slotProps.data),
                            optionLabel: "label",
                            onChange: ($event: any) => (_ctx.onDropdownChange(slotProps.data, $event.value)),
                            class: "custom-dropdown"
                          }, null, 8, ["options", "onChange"]),
                          _cache[3] || (_cache[3] = _createElementVNode("i", { class: "pi pi-cog gear-icon" }, null, -1))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["value", "globalFilter"])
    ]),
    _createVNode(_component_ConfirmationDialog, {
      visible: _ctx.displayConfirmationDialog,
      message: _ctx.confirmationMessage,
      onHide: _ctx.hideConfirmationDialog,
      onConfirm: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmOptionChange(_ctx.selectedOptionData, _ctx.selectedOptionStatus)))
    }, null, 8, ["visible", "message", "onHide"])
  ], 64))
}