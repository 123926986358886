import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'


const _hoisted_1 = { class: "header-content" }
const _hoisted_2 = { class: "header-title" }
const _hoisted_3 = { class: "group" }
const _hoisted_4 = { style: {"color":"white","line-height":"25px"} }
const _hoisted_5 = { class: "group" }
const _hoisted_6 = { style: {"color":"white","line-height":"25px"} }
const _hoisted_7 = { class: "group" }
const _hoisted_8 = { style: {"color":"rgb(40 253 133)","line-height":"23px","text-align":"justify","font-size":"1.4rem"} }
const _hoisted_9 = {
  class: "btn-content",
  style: {"display":"flex","justify-content":"space-evenly"}
}
const _hoisted_10 = {
  class: "btn-baja",
  type: "submit",
  id: "btn-cerrar-popup2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupReactivate = _resolveComponent("PopupReactivate")!

  return (_openBlock(), _createBlock(_component_PopupReactivate, { onClose: _ctx.closePopup }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
          width: "25",
          height: "25",
          src: _imports_0,
          alt: "delete-sign--v1",
          class: "close-icon"
        }),
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('message.planta.f42')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, " SiteID: " + _toDisplayString(_ctx.terminalSelected.siteId), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", _hoisted_6, " Modem: " + _toDisplayString(_ctx.terminalSelected.esn), 1)
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("form", {
        class: "popup-form",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("message.planta.f43")), 1)
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", _hoisted_10, [
            _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f33")), 1)
          ]),
          _createElementVNode("button", {
            class: "btn-baja",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
            type: "button"
          }, [
            _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f34")), 1)
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["onClose"]))
}