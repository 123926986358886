import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"text-align":"center"} }
const _hoisted_2 = { class: "p-dialog-footer button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.visible,
    onHide: _ctx.handleHide,
    modal: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.message), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Button, {
          label: "Cancelar",
          icon: "pi pi-times",
          class: "p-button-text p-button-secondary",
          onClick: _ctx.handleHide
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Aceptar",
          icon: "pi pi-check",
          class: "p-button-text p-button-primary",
          onClick: _ctx.handleConfirm
        }, null, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["visible", "onHide"]))
}