import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'
import _imports_1 from '@/assets/Vector-servicio.png'
import _imports_2 from '@/assets/Vector-search.png'
import _imports_3 from '@/assets/menu.png'
import _imports_4 from '@/assets/checkbox-circle.svg'
import _imports_5 from '@/assets/signal.svg'


const _hoisted_1 = { class: "header-close" }
const _hoisted_2 = { class: "header-content" }
const _hoisted_3 = { style: {"color":"white","text-align":"center"} }
const _hoisted_4 = { class: "file-upload-container" }
const _hoisted_5 = {
  type: "file",
  id: "file-upload",
  class: "file-upload-input",
  ref: "fileInput"
}
const _hoisted_6 = {
  for: "file-upload",
  class: "file-upload-label"
}
const _hoisted_7 = { class: "btn-content" }
const _hoisted_8 = { class: "header-close" }
const _hoisted_9 = { class: "header-content" }
const _hoisted_10 = { style: {"color":"white"} }
const _hoisted_11 = {
  class: "popup-form",
  action: ""
}
const _hoisted_12 = { class: "group" }
const _hoisted_13 = { class: "btn-content" }
const _hoisted_14 = { class: "header-close" }
const _hoisted_15 = { class: "header-content" }
const _hoisted_16 = { style: {"color":"white"} }
const _hoisted_17 = { class: "popup-form" }
const _hoisted_18 = { class: "group" }
const _hoisted_19 = { style: {"color":"white","line-height":"25px"} }
const _hoisted_20 = { class: "btn-content" }
const _hoisted_21 = { class: "header-close" }
const _hoisted_22 = { class: "header-content" }
const _hoisted_23 = { style: {"color":"white"} }
const _hoisted_24 = { class: "group" }
const _hoisted_25 = { style: {"color":"white","line-height":"25px"} }
const _hoisted_26 = { class: "group" }
const _hoisted_27 = { style: {"color":"white","line-height":"25px"} }
const _hoisted_28 = { class: "group" }
const _hoisted_29 = { style: {"color":"#f02647","line-height":"23px","text-align":"justify","font-size":"1.4rem"} }
const _hoisted_30 = {
  class: "btn-content",
  style: {"display":"flex","justify-content":"space-evenly"}
}
const _hoisted_31 = { class: "header-close" }
const _hoisted_32 = { class: "header-content" }
const _hoisted_33 = { style: {"color":"white"} }
const _hoisted_34 = { class: "group" }
const _hoisted_35 = { class: "group" }
const _hoisted_36 = { class: "group" }
const _hoisted_37 = { class: "group" }
const _hoisted_38 = { class: "group" }
const _hoisted_39 = { class: "group" }
const _hoisted_40 = ["placeholder"]
const _hoisted_41 = { class: "btn-content" }
const _hoisted_42 = {
  class: "btn-submit",
  type: "submit",
  id: "btn-cerrar-popup2",
  style: {"margin-top":"1rem","margin-left":"1rem"}
}
const _hoisted_43 = { class: "header-close" }
const _hoisted_44 = { class: "header-content" }
const _hoisted_45 = { style: {"color":"white"} }
const _hoisted_46 = { class: "card flex justify-content-center" }
const _hoisted_47 = {
  class: "card flex justify-content-center gap-3",
  style: {"margin-top":"2rem"}
}
const _hoisted_48 = { class: "header-close" }
const _hoisted_49 = { class: "header-content" }
const _hoisted_50 = { style: {"color":"white"} }
const _hoisted_51 = {
  class: "popup-form",
  action: ""
}
const _hoisted_52 = { class: "group" }
const _hoisted_53 = { style: {"color":"white","line-height":"25px"} }
const _hoisted_54 = { class: "group" }
const _hoisted_55 = { style: {"color":"white","line-height":"25px"} }
const _hoisted_56 = { class: "group" }
const _hoisted_57 = { style: {"color":"#5ad934","line-height":"23px","text-align":"center","font-size":"1.4rem"} }
const _hoisted_58 = {
  class: "btn-content",
  style: {"display":"flex","justify-content":"space-evenly"}
}
const _hoisted_59 = {
  style: {"z-index":"1"},
  class: "table-content"
}
const _hoisted_60 = { class: "buscar-content" }
const _hoisted_61 = { key: 1 }
const _hoisted_62 = {
  for: "file-upload",
  class: "custom-file-upload"
}
const _hoisted_63 = {
  style: {"margin-right":"0px !important"},
  class: "buscar-servicio"
}
const _hoisted_64 = ["placeholder"]
const _hoisted_65 = { class: "btn-content" }
const _hoisted_66 = ["onClick"]
const _hoisted_67 = ["onMouseleave"]
const _hoisted_68 = ["onClick"]
const _hoisted_69 = { class: "flex flex-column align" }
const _hoisted_70 = { class: "flexing-menu" }
const _hoisted_71 = { class: "font-bold" }
const _hoisted_72 = ["onClick"]
const _hoisted_73 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PopupComi = _resolveComponent("PopupComi")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_PopupDrop = _resolveComponent("PopupDrop")!
  const _component_Popup = _resolveComponent("Popup")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_PopupFecha360 = _resolveComponent("PopupFecha360")!
  const _component_newPlant = _resolveComponent("newPlant")!
  const _component_ChangeAdrees = _resolveComponent("ChangeAdrees")!
  const _component_BajaValidation = _resolveComponent("BajaValidation")!
  const _component_requestHigh = _resolveComponent("requestHigh")!
  const _component_changePlan = _resolveComponent("changePlan")!
  const _component_reactivate = _resolveComponent("reactivate")!
  const _component_ChangeModem = _resolveComponent("ChangeModem")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showPopupDrowComisionamiento)
      ? (_openBlock(), _createBlock(_component_PopupComi, {
          key: 0,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("img", {
                style: {"cursor":"pointer"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("message.planta.f9")), 1)
            ]),
            _createElementVNode("form", {
              class: "popup-form",
              action: "",
              onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.confirmSubmitSoporte && _ctx.confirmSubmitSoporte(...args)))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("input", _hoisted_5, null, 512),
                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("message.planta.f10")), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.cargarArchivo()), ["prevent"])),
                    class: "btn-submit",
                    type: "submit",
                    id: "btn-cerrar-popup2",
                    style: {"margin-top":"1rem","margin-left":"1rem"}
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f11")), 1)
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_DataTable, {
                    class: "table-upload-file",
                    style: {"width":"400px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "code",
                        header: "Code"
                      }),
                      _createVNode(_component_Column, {
                        field: "name",
                        header: "Name"
                      }),
                      _createVNode(_component_Column, {
                        field: "category",
                        header: "Category"
                      }),
                      _createVNode(_component_Column, {
                        field: "quantity",
                        header: "Quantity"
                      })
                    ]),
                    _: 1
                  })
                ])
              ])
            ], 32)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupDrow && _ctx.terminalSelected)
      ? (_openBlock(), _createBlock(_component_PopupDrop, {
          key: 1,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("img", {
                style: {"cursor":"pointer","margin-top":"-1rem"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t("message.planta.f12")) + " " + _toDisplayString(String(_ctx.terminalSelected.terminals_nickname).toUpperCase()), 1)
            ]),
            _createElementVNode("form", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.newService.planservice,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newService.planservice) = $event)),
                  options: _ctx.changeserviceplan,
                  label: "name",
                  placeholder: _ctx.$t('message.planta.f13'),
                  class: "custom-select"
                }, null, 8, ["modelValue", "options", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("button", {
                  onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.cambioPlan(_ctx.terminalSelected)), ["prevent"])),
                  class: "btn-submit",
                  type: "submit",
                  id: "btn-cerrar-popup2",
                  style: {"margin-top":"1rem","margin-left":"1rem"}
                }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f14")), 1)
                ])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupDrowSus && _ctx.terminalSelected)
      ? (_openBlock(), _createBlock(_component_PopupDrop, {
          key: 2,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("img", {
                style: {"cursor":"pointer","margin-top":"-1rem"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("h1", _hoisted_16, _toDisplayString(_ctx.$t("message.planta.f28")), 1)
            ]),
            _createElementVNode("form", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("h3", _hoisted_19, _toDisplayString(_ctx.$t("message.planta.f29")) + " " + _toDisplayString(_ctx.terminalSelected.terminals_nickname.toUpperCase()) + " ? ", 1)
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("button", {
                  onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.suspender(_ctx.terminalSelected)), ["prevent"])),
                  class: "btn-submit",
                  type: "submit",
                  id: "btn-cerrar-popup2",
                  style: {"margin-left":"1rem"}
                }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f30")), 1)
                ])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupDrowBaja && _ctx.terminalSelected)
      ? (_openBlock(), _createBlock(_component_PopupDrop, {
          key: 3,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("img", {
                style: {"cursor":"pointer","margin-top":"-1rem"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("h1", _hoisted_23, _toDisplayString(_ctx.$t("message.planta.f36")), 1)
            ]),
            _createElementVNode("form", {
              class: "popup-form",
              action: "",
              onSubmit: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.confirmSubmitBaja && _ctx.confirmSubmitBaja(...args)))
            }, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("h3", _hoisted_25, " SiteID: " + _toDisplayString(_ctx.terminalSelected.siteId), 1)
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("h3", _hoisted_27, " Modem: " + _toDisplayString(_ctx.terminalSelected.esn), 1)
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.$t("message.planta.f35")), 1)
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("button", {
                  onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.bajaTotal(_ctx.terminalSelected)), ["prevent"])),
                  class: "btn-baja",
                  type: "submit",
                  id: "btn-cerrar-popup2"
                }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f33")), 1)
                ]),
                _createElementVNode("button", {
                  class: "btn-baja",
                  onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
                  type: "button"
                }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f34")), 1)
                ])
              ])
            ], 32)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupDrowSoporte && _ctx.terminalSelected)
      ? (_openBlock(), _createBlock(_component_Popup, {
          key: 4,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("img", {
                style: {"cursor":"pointer","margin-top":"-1rem"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("h2", _hoisted_33, _toDisplayString(_ctx.$t("message.planta.f22")) + " " + _toDisplayString(_ctx.terminalSelected.nickname), 1)
            ]),
            _createElementVNode("form", {
              class: "popup-form",
              onSubmit: _cache[19] || (_cache[19] = _withModifiers(($event: any) => (_ctx.generarTicket(_ctx.ticketSoporte, _ctx.terminalSelected)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_34, [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.ticketSoporte.typeContact,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.ticketSoporte.typeContact) = $event)),
                  options: _ctx.problemtypes.dataTypeContactId,
                  label: "name",
                  placeholder: "Selecciona el Tipo de Contacto",
                  class: "custom-select"
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_35, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.ticketSoporte.dataTypeContact) = $event)),
                  class: "custom-input",
                  type: "text",
                  placeholder: "Ingresa el cliente"
                }, null, 512), [
                  [_vModelText, _ctx.ticketSoporte.dataTypeContact]
                ])
              ]),
              _createElementVNode("div", _hoisted_36, [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.ticketSoporte.mediaComunication,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.ticketSoporte.mediaComunication) = $event)),
                  options: _ctx.problemtypes.dataMediaComunicationId,
                  label: "name",
                  placeholder: "Selecciona la Media Comunicacion",
                  class: "custom-select"
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_37, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.ticketSoporte.dataComunication) = $event)),
                  class: "custom-input",
                  type: "text",
                  placeholder: "Ingresa el Medio de Comunicacion"
                }, null, 512), [
                  [_vModelText, _ctx.ticketSoporte.dataComunication]
                ])
              ]),
              _createElementVNode("div", _hoisted_38, [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.ticketSoporte.request,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.ticketSoporte.request) = $event)),
                  options: _ctx.problemtypes.dataPinProblem,
                  label: "name",
                  placeholder: "Selecciona el tipo de solicitud",
                  class: "custom-select"
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_39, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.ticketSoporte.descripcion) = $event)),
                  class: "custom-input",
                  type: "",
                  placeholder: _ctx.$t('message.planta.f26'),
                  style: {"padding-left":"0.5rem","width":"318px","height":"87px"},
                  name: "",
                  id: "",
                  cols: "30",
                  rows: "10"
                }, null, 8, _hoisted_40), [
                  [_vModelText, _ctx.ticketSoporte.descripcion]
                ])
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("button", _hoisted_42, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f27")), 1)
                ])
              ])
            ], 32)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupFecha)
      ? (_openBlock(), _createBlock(_component_PopupFecha360, {
          key: 5,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("img", {
                style: {"cursor":"pointer","margin-top":"-1rem"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("h2", _hoisted_45, _toDisplayString(_ctx.$t("message.planta.f31")), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_46, [
                _createVNode(_component_Calendar, {
                  modelValue: _ctx.dates,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.dates) = $event)),
                  selectionMode: "range",
                  manualInput: false,
                  onDateClick: _ctx.handleDateClick
                }, null, 8, ["modelValue", "onDateClick"])
              ]),
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("a", {
                  onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.gotografanawithDate(_ctx.terminalSelected))),
                  rel: "noopener noreferrer",
                  class: "p-button font-bold"
                }, "Acceder")
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopup)
      ? (_openBlock(), _createBlock(_component_newPlant, {
          key: 6,
          style: {"z-index":"5"},
          onClose: _cache[23] || (_cache[23] = ($event: any) => (_ctx.closePopup()))
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupAddress)
      ? (_openBlock(), _createBlock(_component_ChangeAdrees, {
          key: 7,
          style: {"z-index":"5"},
          terminalSelected: _ctx.terminalSelected,
          onClose: _cache[24] || (_cache[24] = ($event: any) => (_ctx.closePopup()))
        }, null, 8, ["terminalSelected"]))
      : _createCommentVNode("", true),
    (_ctx.showPopupBajaValidation)
      ? (_openBlock(), _createBlock(_component_BajaValidation, {
          key: 8,
          style: {"z-index":"5"},
          terminalSelected: _ctx.terminalSelected,
          onClose: _cache[25] || (_cache[25] = ($event: any) => (_ctx.closePopup()))
        }, null, 8, ["terminalSelected"]))
      : _createCommentVNode("", true),
    (_ctx.showPopupDrowAlta)
      ? (_openBlock(), _createBlock(_component_requestHigh, {
          key: 9,
          style: {"z-index":"5"},
          onClose: _cache[26] || (_cache[26] = ($event: any) => (_ctx.closePopup()))
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupChangePlan)
      ? (_openBlock(), _createBlock(_component_changePlan, {
          key: 10,
          style: {"z-index":"5"},
          terminalSelected: _ctx.terminalSelected,
          onClose: _cache[27] || (_cache[27] = ($event: any) => (_ctx.closePopup()))
        }, null, 8, ["terminalSelected"]))
      : _createCommentVNode("", true),
    (_ctx.showPopupReactivate)
      ? (_openBlock(), _createBlock(_component_reactivate, {
          key: 11,
          style: {"z-index":"5"},
          terminalSelected: _ctx.terminalSelected,
          onClose: _cache[28] || (_cache[28] = ($event: any) => (_ctx.closePopup()))
        }, null, 8, ["terminalSelected"]))
      : _createCommentVNode("", true),
    (_ctx.showPopupDrowChangeModem)
      ? (_openBlock(), _createBlock(_component_ChangeModem, {
          key: 12,
          style: {"z-index":"5"},
          terminalSelected: _ctx.terminalSelected,
          onClose: _cache[29] || (_cache[29] = ($event: any) => (_ctx.closePopup()))
        }, null, 8, ["terminalSelected"]))
      : _createCommentVNode("", true),
    (_ctx.showPopupDrowSuspend && _ctx.terminalSelected)
      ? (_openBlock(), _createBlock(_component_PopupDrop, {
          key: 13,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_48, [
              _createElementVNode("img", {
                style: {"cursor":"pointer","margin-top":"-1rem"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[30] || (_cache[30] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_49, [
              _createElementVNode("h1", _hoisted_50, _toDisplayString(_ctx.$t("message.planta.f39")), 1)
            ]),
            _createElementVNode("form", _hoisted_51, [
              _createElementVNode("div", _hoisted_52, [
                _createElementVNode("h3", _hoisted_53, " SiteID: " + _toDisplayString(_ctx.terminalSelected.siteId), 1)
              ]),
              _createElementVNode("div", _hoisted_54, [
                _createElementVNode("h3", _hoisted_55, " Modem: " + _toDisplayString(_ctx.terminalSelected.esn), 1)
              ]),
              _createElementVNode("div", _hoisted_56, [
                _createElementVNode("p", _hoisted_57, _toDisplayString(_ctx.$t("message.planta.f40")), 1)
              ]),
              _createElementVNode("div", _hoisted_58, [
                _createElementVNode("button", {
                  onClick: _cache[31] || (_cache[31] = _withModifiers(($event: any) => (_ctx.suspendRequest(_ctx.terminalSelected)), ["prevent"])),
                  class: "btn-baja",
                  type: "submit",
                  id: "btn-cerrar-popup2"
                }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f33")), 1)
                ]),
                _createElementVNode("button", {
                  class: "btn-baja",
                  onClick: _cache[32] || (_cache[32] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
                  type: "button"
                }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f34")), 1)
                ])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_59, [
      _createElementVNode("div", _hoisted_60, [
        (_ctx.permissions.some((evt) => evt.id === 115))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              style: {"margin-left":"0px !important","cursor":"pointer"},
              class: "nuevo-servicio",
              onClick: _cache[33] || (_cache[33] = ($event: any) => (_ctx.showPopup = true))
            }, [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t("message.home.btn2")), 1),
              _cache[37] || (_cache[37] = _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "img-vector"
                })
              ], -1))
            ]))
          : _createCommentVNode("", true),
        (_ctx.permissions.some((evt) => evt.id === 114))
          ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
              _createElementVNode("label", _hoisted_62, [
                _createElementVNode("input", {
                  id: "file-upload",
                  type: "file",
                  ref: "fileInput",
                  accept: ".csv",
                  onChange: _cache[34] || (_cache[34] = 
//@ts-ignore
(...args) => (_ctx.confirmUpload && _ctx.confirmUpload(...args)))
                }, null, 544),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("message.planta.f38")), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_63, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.query) = $event)),
            class: "buscador",
            type: "text",
            placeholder: _ctx.$t('message.planta.search'),
            name: "",
            id: "",
            style: {"font-size":"16px"}
          }, null, 8, _hoisted_64), [
            [_vModelText, _ctx.query]
          ]),
          _cache[38] || (_cache[38] = _createElementVNode("button", { class: "circle" }, [
            _createElementVNode("img", {
              src: _imports_2,
              alt: "img-search"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_65, [
            _createElementVNode("button", {
              onClick: _cache[36] || (_cache[36] = ($event: any) => (_ctx.exportCSV($event)))
            }, _toDisplayString(_ctx.$t("message.export")), 1)
          ])
        ])
      ]),
      _createVNode(_component_DataTable, {
        value: _ctx.filteredTerminals,
        paginator: "",
        rows: 8,
        ref: "dt",
        rowsPerPageOptions: [8],
        stripedRows: "",
        tableStyle: "min-width: 1300px"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myColumnTablet, (column, index) => {
            return (_openBlock(), _createBlock(_component_Column, {
              class: "title-c",
              key: 'column-' + index,
              sortable: column.sort,
              field: column.value,
              header: _ctx.$t(column.ts),
              style: 'color:#000000!important;text-align:center;'
            }, _createSlots({ _: 2 }, [
              (column.childrem && column.name === 'Action')
                ? {
                    name: "body",
                    fn: _withCtx(({ data }) => [
                      _createElementVNode("button", {
                        class: "menu-button",
                        onClick: _withModifiers(($event: any) => (
            data.show === true ? (data.show = false) : (data.show = true)
            ), ["prevent"])
                      }, _cache[39] || (_cache[39] = [
                        _createElementVNode("img", {
                          src: _imports_3,
                          alt: "burguer-menu"
                        }, null, -1)
                      ]), 8, _hoisted_66),
                      _createElementVNode("form", {
                        onMouseleave: ($event: any) => (data.show = false)
                      }, [
                        _createVNode(_component_Menu, {
                          id: "overlay_menu",
                          ref_for: true,
                          ref: "menu",
                          style: {"position":"absolute"},
                          popup: !data.show
                        }, {
                          start: _withCtx(() => [
                            (_ctx.permissions.some((evt) => evt.id === 12))
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  onClick: ($event: any) => {
                  _ctx.showPopupDrowComisionamiento = true;
                data.show = false;
                },
                                  class: "w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                                }, [
                                  _createVNode(_component_Avatar, {
                                    image: "https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png",
                                    class: "mr-2",
                                    shape: "circle"
                                  }),
                                  _createElementVNode("div", _hoisted_69, [
                                    _createElementVNode("div", _hoisted_70, [
                                      _cache[40] || (_cache[40] = _createElementVNode("img", {
                                        src: _imports_4,
                                        alt: ""
                                      }, null, -1)),
                                      _createElementVNode("span", _hoisted_71, _toDisplayString(_ctx.$t("message.planta.op1")), 1)
                                    ])
                                  ])
                                ], 8, _hoisted_68))
                              : _createCommentVNode("", true),
                            (_ctx.permissions.some((evt) => evt.id === 42))
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 1,
                                  onClick: _withModifiers(($event: any) => (_ctx.gotografana(data)), ["prevent"]),
                                  class: "w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                                }, [
                                  _createVNode(_component_Avatar, {
                                    image: "https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png",
                                    class: "mr-2",
                                    shape: "circle"
                                  }),
                                  _cache[41] || (_cache[41] = _createElementVNode("div", { class: "flex flex-column align" }, [
                                    _createElementVNode("div", { class: "flexing-menu" }, [
                                      _createElementVNode("img", {
                                        src: _imports_5,
                                        alt: ""
                                      }),
                                      _createElementVNode("span", { class: "font-bold" }, " 360 ")
                                    ])
                                  ], -1))
                                ], 8, _hoisted_72))
                              : _createCommentVNode("", true),
                            (_ctx.permissions.some((evt) => evt.id === 42))
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 2,
                                  onClick: ($event: any) => {
                  _ctx.showPopupFecha = true;
                _ctx.terminalSelect(data);
                data.show = false;
                },
                                  class: "w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                                }, [
                                  _createVNode(_component_Avatar, {
                                    image: "https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png",
                                    class: "mr-2",
                                    shape: "circle"
                                  }),
                                  _cache[42] || (_cache[42] = _createElementVNode("div", { class: "flex flex-column align" }, [
                                    _createElementVNode("div", { class: "flexing-menu" }, [
                                      _createElementVNode("img", {
                                        src: _imports_5,
                                        alt: ""
                                      }),
                                      _createElementVNode("span", { class: "font-bold" }, " CUSTOM 360 ")
                                    ])
                                  ], -1))
                                ], 8, _hoisted_73))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["popup"])
                      ], 40, _hoisted_67)
                    ]),
                    key: "0"
                  }
                : (column.childrem && column.name === 'Estado')
                  ? {
                      name: "body",
                      fn: _withCtx(({ data }) => [
                        _createElementVNode("button", {
                          class: _normalizeClass(data.statusTerminal === 'Online' ? 'btn-active' : 'btn-inactive'
            )
                        }, _toDisplayString(data.statusTerminal === "Online" ? "Online" : "Offline"), 3)
                      ]),
                      key: "1"
                    }
                  : (column.childrem && column.name === 'Ultima Conexión')
                    ? {
                        name: "body",
                        fn: _withCtx(({ data }) => [
                          _createTextVNode(_toDisplayString(String(data.billingConsumption_lastDailyDate).substring(0, 10)), 1)
                        ]),
                        key: "2"
                      }
                    : (column.childrem && column.name === 'Dirección')
                      ? {
                          name: "body",
                          fn: _withCtx(({ data }) => [
                            _createTextVNode(_toDisplayString(String(data.addressRoute).substring(0, 40)), 1)
                          ]),
                          key: "3"
                        }
                      : (column.childrem && column.name === 'RFS')
                        ? {
                            name: "body",
                            fn: _withCtx(({ data }) => [
                              _createTextVNode(_toDisplayString(String(data.activationDate).substring(0, 10)), 1)
                            ]),
                            key: "4"
                          }
                        : undefined
            ]), 1032, ["sortable", "field", "header"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ], 64))
}